import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../fonts/fonts.css"
import colors from "../components/colors"
import styled from "@emotion/styled"

const Header = styled.div`
  font-family: Messapia Bold;
  color: ${colors.navy};
  text-shadow: -2px -2px ${colors.darkpink};
  text-align: center;
`

const Text = styled.div`
  font-family: Messapia Bold;
  text-align: center;
  padding: 1rem;
`

const NotFoundPage = () => (

  <Layout>
    <SEO title="404: Not found" />
    <Header>404: NOT FOUND</Header>
    <Text>ur no longer in the groove</Text>
  </Layout>
)

export default NotFoundPage
